import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
export default function Component({ className }) {
  return (
    <div className={`w-auto h-auto bg-black relative`}>
      <div className="container mx-auto px-3 lg:px-20">
        <div className="grid grid-cols-12 gap-2  py-8 md:pt-16 justify-items-stretch">
          <div className="col-span-12 md:col-span-4 lg:col-span-2">
            <Link to="/">
              <StaticImage
                src="../../static/images/logo-transparent.png"
                className="h-20 sm:h-28 lg:mx-0"
                layout="fixed"
                width={100}
                alt="logo"
              />
            </Link>
          </div>
          <div className="col-span-12 md:col-span-8 lg:col-span-4 w-full md:w-foot -ml-0 xl:-ml-14 text-left py-5 sm:py-0 text-h-0.5 sm:text-h-1.5 leading-5 sm:leading-6 ">
            <p className="font-serif text-white">
              VILLAS OF IBIZA is a specialist real estate agency selling
              beautiful homes. Homes need to bring comfort and joy of family
              life to everyday living.
            </p>
            <p className="font-serif text-white">
              Beautiful homes make people happy.
            </p>
            <p className="font-serif text-white">
              Beautiful homes help us live better and are a reflection of who we
              are. <br /> A design-led agency that helps discerning people
              fulfil their Ibiza dreams.
            </p>
          </div>
          <div className="col-span-12 md:col-span-12 lg:col-span-2 justify-self-center">
            <div className="flex space-x-6 lg:space-x-0 lg:grid lg:gap-2 items-center text-social pb-6 lg:pb-0">
              <a
                className="outline-none focus:outline-none"
                href="https://www.instagram.com/villasofibiza/"
                target="_blank"
                rel="noreferrer"
              >
                <svg viewBox="0 0 50 50" className="h-4 w-4  fill-current">
                  <g>
                    <g>
                      <path
                        className="st0"
                        d="M34.4,0H15.6C7,0,0,7,0,15.6v18.8C0,43,7,50,15.6,50h18.8C43,50,50,43,50,34.4V15.6C50,7,43,0,34.4,0z
			 M45.3,34.4c0,6-4.9,10.9-10.9,10.9H15.6c-6,0-10.9-4.9-10.9-10.9V15.6c0-6,4.9-10.9,10.9-10.9h18.8c6,0,10.9,4.9,10.9,10.9V34.4z
			"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        className="st0"
                        d="M25,12.5c-6.9,0-12.5,5.6-12.5,12.5S18.1,37.5,25,37.5S37.5,31.9,37.5,25S31.9,12.5,25,12.5z M25,32.8
			c-4.3,0-7.8-3.5-7.8-7.8c0-4.3,3.5-7.8,7.8-7.8s7.8,3.5,7.8,7.8C32.8,29.3,29.3,32.8,25,32.8z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <circle className="st0" cx="38.4" cy="11.6" r="1.7" />
                    </g>
                  </g>
                </svg>
              </a>
              <a
                className="outline-none focus:outline-none"
                href="https://www.facebook.com/Villas-of-Ibiza-103957855083338"
                target="_blank"
                rel="noreferrer"
              >
                <svg className="h-4 w-4  fill-current" viewBox="0 0 50 50">
                  <g>
                    <g>
                      <path
                        className="st0"
                        d="M43.8,0H6.2C2.8,0,0,2.8,0,6.2v37.5C0,47.2,2.8,50,6.2,50H25V32.8h-6.2V25H25v-6.2c0-5.2,4.2-9.4,9.4-9.4h6.2
			v7.8h-3.1c-1.7,0-3.1-0.2-3.1,1.6V25h7.8l-3.1,7.8h-4.7V50h9.4c3.4,0,6.2-2.8,6.2-6.2V6.2C50,2.8,47.2,0,43.8,0z"
                      />
                    </g>
                  </g>
                </svg>
              </a>
              <a
                className="outline-none focus:outline-none"
                href="https://www.linkedin.com/company/villas-of-ibiza"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  version="1.1"
                  id="Capa_1"
                  viewBox="0 0 50 50"
                  className="h-4 w-4 fill-current"
                >
                  <g>
                    <g>
                      <rect
                        y="15.6"
                        className="st0"
                        width="11.2"
                        height="34.4"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        className="st0"
                        d="M41.6,16c-0.1,0-0.2-0.1-0.4-0.1c-0.2,0-0.3-0.1-0.5-0.1c-0.6-0.1-1.2-0.2-2-0.2c-6.5,0-10.7,4.7-12,6.6v-6.6
			H15.6V50h11.2V31.2c0,0,8.4-11.8,12-3.1c0,7.7,0,21.9,0,21.9H50V26.8C50,21.6,46.4,17.3,41.6,16z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <circle className="st0" cx="5.5" cy="5.5" r="5.5" />
                    </g>
                  </g>
                </svg>
              </a>
              <a
                className="outline-none focus:outline-none"
                href="https://www.youtube.com/channel/UC3IVAj6dzGPFYdFV68OAaMg"
                target="_blank"
                rel="noreferrer"
              >
                <svg className="h-4 w-4 fill-current" viewBox="0 0 50 50">
                  <g>
                    <g>
                      <path
                        className="st0"
                        d="M47.9,11.1c-1.4-2.4-2.8-2.9-5.8-3c-3-0.2-10.5-0.3-17-0.3c-6.5,0-14.1,0.1-17.1,0.3c-3,0.2-4.5,0.6-5.8,3
			C0.7,13.5,0,17.7,0,25c0,0,0,0,0,0c0,0,0,0,0,0v0c0,7.3,0.7,11.5,2.1,13.8c1.4,2.4,2.8,2.8,5.8,3.1c3,0.2,10.5,0.3,17.1,0.3
			c6.5,0,14-0.1,17-0.3c3-0.2,4.5-0.6,5.8-3.1C49.3,36.5,50,32.3,50,25c0,0,0,0,0,0c0,0,0,0,0,0C50,17.7,49.3,13.5,47.9,11.1z
			 M18.8,34.4V15.6L34.4,25L18.8,34.4z"
                      />
                    </g>
                  </g>
                </svg>
              </a>
              <a
                className="outline-none focus:outline-none"
                href="http://pinterest.com/VillasofIbiza/"
                target="_blank"
                rel="noreferrer"
              >
                <svg viewBox="0 0 50 61.5" className="h-4 w-4  fill-current">
                  <g>
                    <g>
                      <path
                        className="st0"
                        d="M25.8,0C9,0,0,10.8,0,22.6C0,28.1,3.1,34.9,7.9,37c0.7,0.3,1.1,0.2,1.3-0.5c0.1-0.5,0.8-3,1.1-4.2
			c0.1-0.4,0-0.7-0.3-1.1c-1.6-1.9-2.9-5.3-2.9-8.5c0-8.2,6.5-16.2,17.7-16.2c9.6,0,16.3,6.2,16.3,15.2c0,10.1-5.3,17.1-12.3,17.1
			c-3.8,0-6.7-3-5.8-6.7c1.1-4.4,3.2-9.2,3.2-12.4c0-2.9-1.6-5.3-4.9-5.3c-3.9,0-7.1,3.9-7.1,9.1c0,3.3,1.2,5.5,1.2,5.5
			s-3.9,15.6-4.6,18.6c-1.2,4.9,0.2,12.9,0.3,13.6c0.1,0.4,0.5,0.5,0.7,0.2c0.4-0.5,5.1-7.2,6.4-12c0.5-1.8,2.4-8.9,2.4-8.9
			c1.3,2.3,5,4.3,9,4.3C41.5,44.8,50,34.4,50,21.5C50,9.2,39.4,0,25.8,0z"
                      />
                    </g>
                  </g>
                </svg>
              </a>
            </div>
          </div>
          <div className="col-span-6 md:col-span-6 lg:col-span-2 text-white justify-self-start md:justify-self-center">
            <h3 className="text-h-1 font-serif text-left lg:text-right tracking-widest leading-4 mb-3.5 text-gray-300">
              Quick Links
            </h3>
            <ul className="text-white text-left lg:text-right font-sans-serif tracking-xl text-h-1.5 sm:text-h-2">
              <li className="font-sans-serif py-1">
                <Link to="/about">ABOUT US</Link>
              </li>
              <li className="font-sans-serif py-1">
                <Link to="/buyer">I AM A BUYER</Link>
              </li>
              <li className="font-sans-serif py-1">
                <Link to="/seller">I AM A SELLER</Link>
              </li>
            </ul>
          </div>
          <div className="col-span-6 lg:col-span-2 justify-self-end">
            <h3 className="text-left lg:text-right text-h-1 text font-serif tracking-widest leading-4 mb-3.5 text-gray-300">
              Important Info
            </h3>
            <ul className="text-white text-left lg:text-right font-sans-serif tracking-xl text-h-1.5 sm:text-h-2">
              <li className="font-sans-serif py-1">
                <Link to="/contact">CONTACT US</Link>
              </li>
              <li className="font-sans-serif py-1">
                <Link to="/privacy-policy">PRIVACY POLICY</Link>
              </li>
              <li className="font-sans-serif py-1">
                <Link to="/terms">T&Cs</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="flex text-h-0.5 lg:text-h-2 text-white justify-center pb-8">
        <div className="px-3 mb-3">
          © Villas of Ibiza {new Date().getFullYear()}
        </div>
        <div className="px-3 mb-3 border-l-2 border-white border-r-2">
          + 34 626 523 371{" "}
        </div>
        <div className="px-3 mb-3">
          <a
            href="mailto:info@villasofibiza.com"
            className="font-serif text-h-0.5 sm:text-h-1 text-mustard-yellow"
          >
            info@villasofibiza.com
          </a>
        </div>
      </div>
    </div>
  );
}
