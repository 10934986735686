import React, { useEffect } from "react";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";

import "./index.css";
import "./algolia.css";
import { WishlistProvider } from "../context/WishlistContext";
import StickyBar from "../components/StickyBar";

export default function Layout({ children }) {
  useEffect(() => {
    window.addEventListener(
      "contextmenu",
      function (e) {
        e.preventDefault();
      },
      false
    );

    return () => {
      window.removeEventListener("contextmenu");
    };
  }, []);

  return (
    <WishlistProvider>
      <div className="mx-auto">
        <NavBar />
        <div className="flex-grow min-h-screen overflow-y-auto">{children}</div>
        <Footer />
        <StickyBar />
      </div>
    </WishlistProvider>
  );
}
