import React, { useContext } from "react";
import { WishlistContext } from "../context/WishlistContext";

export default function StickyBar() {
  const { itemCount } = useContext(WishlistContext);

  if (!itemCount || itemCount === 0) return <React.Fragment />;

  return (
    <div className="fixed w-full z-50 bottom-0 h-16 bg-white border-t border-gray-200">
      h
    </div>
  );
}
