import React, {
  // useEffect,
  useState,
} from "react";

// import useLocalStorage from "react-use-localstorage";

// import * as cartUtil from "../utils/cartUtil";

const Context = React.createContext();

const Provider = (props) => {
  const [
    cart,
    // setCart
  ] = useState();
  const [itemCount, setItemCount] = useState(0);

  const add = () => {
    console.log("adding");
    setItemCount(1);
  };

  return (
    <Context.Provider value={{ add, cart, itemCount }}>
      {props.children}
    </Context.Provider>
  );
};

export { Context as WishlistContext, Provider as WishlistProvider };
